<template>
  <div class="p-8">
    <sqr-page-header title="Paramètres" />
    <div class="mt-8 bg-white shadow overflow-hidden sm:rounded-md max-w-xl">
      <ul>
        <li class="list-item">
          <router-link class="link" :to="{ name: 'insurers', params: { bid } }">
            <div class="link-content">
              <div>Les compagnies d'assurances</div>
              <fa :icon="['far', 'chevron-right']" />
            </div>
          </router-link>
        </li>

        <li class="list-item">
          <router-link class="link" :to="{ name: 'branches', params: { bid } }">
            <div class="link-content">
              <div>Les branches</div>
              <fa :icon="['far', 'chevron-right']" />
            </div>
          </router-link>
        </li>

        <li class="list-item">
          <router-link
            class="link"
            :to="{ name: 'inquiry-models', params: { bid } }"
          >
            <div class="link-content">
              <div>Modèles des demandes d'offres</div>
              <fa :icon="['far', 'chevron-right']" />
            </div>
          </router-link>
        </li>

        <li class="list-item">
          <router-link
            class="link"
            :to="{ name: 'emails-templates', params: { bid } }"
          >
            <div class="link-content">
              <div>Modèles des emails</div>
              <fa :icon="['far', 'chevron-right']" />
            </div>
          </router-link>
        </li>

        <li class="list-item">
          <router-link
            class="link"
            :to="{ name: 'settings-email', params: { bid } }"
          >
            <div class="link-content">
              <div>Service d'expédition des emails</div>
              <fa :icon="['far', 'chevron-right']" />
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.list-item {
  @apply border-t border-gray-200;
}
.list-item:first-child {
  @apply border-t-0;
}
.link-item {
  @apply block transition duration-150 ease-in-out;
}
.list-item:hover {
  @apply bg-gray-50;
}
.link:focus {
  @apply outline-none bg-gray-50;
}
.link-content {
  @apply flex items-center px-2 py-2 justify-between;
}
@screen sm {
  .link-content {
    @apply px-3;
  }
}
</style>

<script>
import broker from '../broker';
export default {
  name: 'Settings',
  mixins: [broker]
};
</script>
